import axios from 'axios';

export let headers = {
  'Tenant-Access-Token': 'MWRlNDNmZjU3YzdiZWNjODAzNjIxMzc1M2Q5NjZmMWI=',
  'Tenant-Access-Key': 'tvjUrC5i6MyCBw',
};

export const api = axios.create({
  baseURL: 'https://immersive-game-india.confirmu.com',
  headers: {
    'Tenant-Access-Token': 'MWRlNDNmZjU3YzdiZWNjODAzNjIxMzc1M2Q5NjZmMWI=',
    'Tenant-Access-Key': 'tvjUrC5i6MyCBw',
  },
});

export const BASE_URL = 'https://immersive-game-india.confirmu.com';
