import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { isMobile } from "react-device-detect";
import "../utils/gameLayout.scss";
import RotateImg from "../assets/images/rotate-mobile.png";
import CUlogo from "../assets/images/confirmu-logo.png";

import GameStart from "./../components/gamestart/index.js";
import SplashScreen from "../components/splashscreen/index.js";
import Registration from "./../components/registration/index.js";

import cx from "classnames";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import CurrentComponent from "../components/currentcomponent/index.js";
import AsherIntro from "../components/asherintro/index.js";
import { connect } from "react-redux";
import playMusic from "../utils/playMusic";
import EN from "../assets/audio/rotateText/EN.mp3";
import HI from "../assets/audio/rotateText/HI.mp3";
import MR from "../assets/audio/rotateText/MR.mp3";
import KN from "../assets/audio/rotateText/KN.mp3";
import TE from "../assets/audio/rotateText/TE.mp3";
import PU from "../assets/audio/rotateText/PU.mp3";
import TA from "../assets/audio/rotateText/TA.mp3";
import GU from "../assets/audio/rotateText/GU.mp3";

const isPortrait = () => {
	var mql = window.matchMedia("(orientation: portrait)");
	return mql.matches;
};

let _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

const CuLayout = (props) => {
	const [desktop, setDesktop] = useState(false);
	const [page, setPage] = useState("GameStart");
	const [submittedMobile, setData] = useState("");
	const [portraitMode, setMode] = useState(isPortrait());
	const [selectedLanguage, setSelectedLanguage] = useState(
		props.userSelectedLanguage
	);

	useEffect(() => {
		setDesktop(!isMobile);
		window.addEventListener(
			"resize",
			function () {
				setMode(isPortrait());
			},
			false
		);
	}, []);

	const fullScreen = () => {
		let body = document.getElementsByTagName("body")[0];
		if (body.requestFullscreen) return body.requestFullscreen();
		else if (body.webkitRequestFullScreen)
			return body.webkitRequestFullScreen();
	};

	const components = {
		GameStart,
		SplashScreen,
		Registration,
		CurrentComponent,
		AsherIntro,
	};

	const gotToPage = (page, mobile) => {
		if (_iOSDevice) {
			setOtherData(page, mobile);
			if (page === "GameStart" && !desktop) {
				fullScreen();
			}
			return;
		}
		if (page === "GameStart") {
			fullScreen()
				.then(() => {
					window.screen
						.lockOrientation("landscape")
						.then(function () {
							setOtherData(page, mobile);
						})
						.catch(function (error) {
							//no force rotate support
							setOtherData(page, mobile);
						});
				})
				.catch(() => {
					//no fullscreen support
					setOtherData(page, mobile);
				});
		} else {
			setOtherData(page, mobile);
		}
	};
	const setOtherData = (page, mobile) => {
		setPage(page);
		if (mobile) setData(mobile);
	};

	// rotate screen text of diffrent language
	const [text, setText] = useState("");
	const [voice, setVoice] = useState("");

	useEffect(() => {
		if (selectedLanguage === "en") {
			setText("Please rotate the device to Landscape mode to continue");
			setVoice(EN);
		}
		if (selectedLanguage === "hi") {
			setText("जारी रखने के लिए कृपया डिवाइस को लैंडस्केप मोड में घुमाएं");
			setVoice(HI);
		}
		if (selectedLanguage === "mr") {
			setText("कृपया सुरू ठेवण्यासाठी डिव्हाइसला लँडस्केप मोडमध्ये फिरवा");
			setVoice(MR);
		}
		if (selectedLanguage === "kn") {
			setText("ಮುಂದುವರಿಸಲು ದಯವಿಟ್ಟು ಸಾಧನವನ್ನು ಲ್ಯಾಂಡ್‌ಸ್ಕೇಪ್ ಮೋಡ್‌ಗೆ ತಿರುಗಿಸಿ");
			setVoice(KN);
		}
		if (selectedLanguage === "te") {
			setText(
				"దయచేసి కొనసాగించడానికి పరికరాన్ని ల్యాండ్‌స్కేప్ మోడ్‌కి తిప్పండి"
			);
			setVoice(TE);
		}
		if (selectedLanguage === "pa") {
			setText("ਕਿਰਪਾ ਕਰਕੇ ਜਾਰੀ ਰੱਖਣ ਲਈ ਡਿਵਾਈਸ ਨੂੰ ਲੈਂਡਸਕੇਪ ਮੋਡ ਵਿੱਚ ਘੁੰਮਾਓ");
			setVoice(PU);
		}
		if (selectedLanguage === "ta") {
			setText("தொடர, சாதனத்தை லேண்ட்ஸ்கேப் பயன்முறையில் சுழற்றுங்கள்");
			setVoice(TA);
		}
		if (selectedLanguage === "gu") {
			setText("ચાલુ રાખવા માટે કૃપા કરીને ઉપકરણને લેન્ડસ્કેપ મોડમાં ફેરવો");
			setVoice(GU);
		}
	}, [selectedLanguage, text]);

	//rotate text voiceover
	const audioRef = useRef(null);
	if (portraitMode) {
		audioRef.current = playMusic(voice);
	}

	const render = (page) => {
		let SpecificPage = components[page];
		return (
			<div className={cx("w-h-100", { portraitMode: portraitMode })}>
				{" "}
				{portraitMode && page === "GameStart" && (
					<div className="rotate_screen">
						<div>
							<h3>Please rotate the device to Landscape mode to continue</h3>
							<h4>कृपया जारी रखने के लिए डिवाइस को लैंडस्केप मोड में घुमाएँ</h4>
							<img src={RotateImg}></img>
							<img src={CUlogo}></img>
						</div>
					</div>
				)}
				<SpecificPage
					history={props.history}
					gotToPage={gotToPage}
					mobile={submittedMobile}
				/>
			</div>
		);
	};

	return <div className={classnames("main")}>{render(page)}</div>;
};

const mapStateToProps = (state) => {
	return {
		userSelectedLanguage: state.trackingUser.lang,
	};
};
export default connect(mapStateToProps)(CuLayout);
